@import "index.css";

.container {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 72px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  z-index: 11;
  background-color: var(--white-or-black);
  padding: 0 16px;
  box-shadow: var(--shadow);
  margin: 16px;
  border-radius: 4px;
}

.logo {
  height: 54px;
  width: auto;
}

.link,
.mobileLink,
.outlinedLink,
.mobileLogin {
  font-family: var(--title-font);
  font-size: 14px;
  margin: 8px 16px;
  color: var(--secondary-typography-color);
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 400 !important;
}

.link:hover {
  color: var(--blue-secondary);
}

.otherLoginOptions {
  background-color: var(--blue-secondary);
  border: 2px;
  color: var(--white-or-black);
  margin: 8px 16px;
  font-family: var(--title-font);
  font-size: 14px;
  font-weight: 400;
}

.menu {
  margin: 0 !important;
  padding: 0 !important;
}

.menuItem {
  background-color: var(--blue-secondary) !important;
  border: 2px;
  text-transform: uppercase;
  color: white !important;
  font-family: var(--title-font);
  font-size: 14px;
  font-weight: 400;
  padding: 16px 24px !important;
  border-bottom: 1px solid white;
}

.menuItem:last-of-type {
  border-bottom: none;
}

.menuItem:hover,
.menuItem:visited {
  background-color: var(--blue-secondary);
  opacity: 0.8;
}

.drawerIcon {
  position: fixed;
  top: 0;
  right: 0;
  margin: 16px;
  color: var(--white-or-black);
  z-index: 100;
  min-height: 32px !important;
  min-width: 32px !important;
  height: 32px !important;
  width: 32px !important;
}

.drawerContent {
  height: 100vh;
  position: relative;
  padding: 8px;
}

.closeIcon {
  position: absolute;
  top: 0;
  right: 0;
  margin: 16px;
}

.menuList {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  top: 64px;
  position: absolute;
  left: 16px;
  width: calc(100% - 32px);
}

.mobileLink {
  font-size: 16px;
  margin: 16px;
  color: var(--blue-secondary);
  text-align: left;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.caret {
  margin-left: 12px;
}

.linkIcon {
  margin-right: 12px;
}

.mobileLogin {
  border: 2px solid var(--blue-secondary);
  padding: 4px;
  font-size: 20px;
  margin: 16px auto;
  border-radius: 4px;
  width: 70%;
  text-align: center;
  color: var(--blue-secondary);
}

.plainLinks {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}
