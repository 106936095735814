@import "index.css";

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--primary);
  z-index: 999;
}

.progress {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #fff !important;
}
