.appBar {
  height: 64px;
  background-color: #20595f;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
}

.logo {
  position: absolute;
  top: 0;
  left: 0;
  height: 52px;
  width: auto;
  margin: 6px 12px;
}

.mobileLogo {
  margin: 0;
  border-radius: 0;
  height: 64px;
  width: 64px;
}

/* @media (prefers-color-scheme: dark) {
  .appBar {
    background-color: #181818 !important;
  }
} */
