@import "index.css";

.report,
.refresh {
  box-shadow: none;
  font-size: 18px;
  border: 2px solid var(--white-or-black);
  color: var(--white-or-black);
  background-color: var(--red);
  font-family: var(--main-font);
  border-radius: 4px;
  cursor: pointer;
  padding: 16px;
  width: 254px;
  margin: 4px 0;
}

.refresh {
  background-color: var(--blue);
}

.title {
  font-size: 32px;
  font-family: var(--title-font);
  margin: 32px 0;
  text-align: center;
}

.container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.backgroundImage {
  position: fixed;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url("./bulbs.png");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
}

.backgroundImage::after {
  position: absolute;
  content: " ";
  display: block;
  background: var(--darkred);
  opacity: 0.7;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.grayIcon {
  fill: var(--gray);
}

.paper {
  width: 300px;
  height: 375px;
  opacity: 1;
  padding: 16px;
  background-color: var(--white-or-black);
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
