@import "index.css";

.banner {
  z-index: 1200;
  font-family: var(--mui-font);
  position: fixed;
  border-radius: 4px;
  top: 0;
  width: 450px;
  right: 0;
  margin: 16px;
  height: 52px;
  background-color: var(--blue-secondary);
  box-shadow: var(--shadow);
  color: var(--white-or-black);
  padding: 4px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}

.closeLabel {
  font-weight: 400;
  cursor: pointer;
}

.closeLabel:hover {
  opacity: 0.8;
}

.link {
  text-decoration: underline;
  color: var(--white-or-black);
  margin-left: 8px;
  font-weight: 500;
}

.link:hover {
  opacity: 0.8;
}
