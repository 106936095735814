:root {
  --white-or-black: #fff;
  --background: #fafafa;
  --blue: #199dd6;
  --blue-secondary: #a383a7;
  --purple: #633c5b;
  --teal: #20595f;
  --green: #4b9382;
  --bright-blue: #6dd5fa;
  --red: #ef5350;
  --gray: #777;
  --faded: #eaeaea;
  --light-gray: #d8d8d8;
  --primary: #2c3d49;
  --bluegray: #282c34;
  --darkred: #7a0b23;
  --secondary-typography-color: #2b2b2b;
  --orange: #ffa500;
  --yellow: #d2db5c;
  --emerald: #2d8f4e;
  --dark-green: #084137;
  --title-font: "Raleway", sans-serif;
  --main-font: "Open Sans", sans-serif;
  --mui-font: "Roboto", sans-serif;
  --cursive: "Lobster Two", cursive;
  --display: "Monoton", cursive;
  --green-gradient: linear-gradient(to right, #43a047, #48942a, #0c752b);
  --light-green-gradient: linear-gradient(to right, #a8e063, #56ab2f);
  --dark-gradient: linear-gradient(to right, #0f2027, #203a43, #020202);
  --blue-gradient: linear-gradient(-40deg, #2193b0, #0575e6, #199dd6);
  --red-gradient: linear-gradient(to right, #ed213a, #93291e);
  --yellow-gradient: linear-gradient(to right, #fceabb, #f8b500);
  --orange-gradient: linear-gradient(to right, #e44d26, #f16529);
  --shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.15);
  --half-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  --faded-blue-background: rgba(59, 134, 176, 0.05);
}

html {
  height: 100%;
}

body,
#root {
  min-height: 100%;
  background-color: var(--background);
  margin: 0;
}

#root {
  max-width: 2080px;
  margin: 0 auto !important;
  box-shadow: var(--shadow);
  position: relative;
}

svg *:focus {
  outline: none !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #fff inset;
}

.freshwidget-theme {
  font-family: var(--mui-font);
  font-size: 18px;
  font-weight: 400;
  display: none;
}

kbd {
  border: 1px solid var(--blue-secondary);
  color: var(--blue-secondary);
  font-weight: 400;
  font-family: var(--mui-font);
  border-radius: 1px;
  padding: 2px 4px;
  text-transform: uppercase;
  font-size: 10px;
  white-space: nowrap;
}

/* @media (prefers-color-scheme: dark) {
  :root {
    --white-or-black: #3a3b3c;
    --background: #121212;
    --gray: rgba(255, 255, 255, 0.7);
    --faded: rgba(255, 255, 255, 0.4);
    --light-gray: #b3b3b3;
    --secondary-typography-color: #fff;
    --shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.8);
    --half-shadow: 0 2px 7px rgba(0, 0, 0, 0.3);
    --green: #09d3ac;
    --orange: #ffa500;
    --yellow: #ffff00;
    --red: #ff4500;
    --blue: #199dd6;
    --blue-secondary: #3b86b0;
    --teal: #2193b0;
  }

  body,
  #root {
    background-color: var(--background);
  }

  input {
    background: transparent !important;
    color: white !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: 0 0 0px 1000px var(--background) inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  svg {
    filter: brightness(0.7);
  }
} */

@media (prefers-reduced-motion: reduce) {
  body {
    scroll-behavior: auto;
  }

  * {
    -webkit-animation: none !important;
    animation: none !important;
    transition: none !important;
  }
}
