@import "index.css";

.container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.backgroundImage {
  position: fixed;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: url("./workplace_new.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.backgroundImage::after {
  position: absolute;
  content: " ";
  display: block;
  background: var(--purple);
  opacity: 0.7;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.paper {
  width: 300px;
  height: auto;
  opacity: 1;
  background-color: var(--white-or-black);
  z-index: 4;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
}

.headerArea {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100%;
  padding: 16px;
}

.img {
  width: 64px;
  color: yellow;
  margin-top: 12px;
  height: 64px;
  border: 2px solid green;
  border-radius: 32px;
  padding: 8px;
}

.title {
  height: 62px;
  margin-left: 24px;
  font-size: 20px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  flex-direction: column;
}

.divider {
  width: 100%;
  margin: 12px 0;
}

.name {
  font-family: var(--main-font);
}

.message {
  font-family: var(--title-font);
  font-size: 16px;
}

.signUpArea {
  padding: 16px;
  width: 100%;
}

.input {
  margin: 6px 0;
}

.createAccount {
  margin: 16px auto !important;
  margin-bottom: 4px !important;
  display: flex !important;
  justify-content: center !important;
  font-family: var(--main-font) !important;
  text-align: center;
  font-size: 16px;
  width: 100%;
  padding: 12px !important;
  border-color: var(--green);
  color: var(--green);
}

.buttonText {
  text-transform: capitalize !important;
}

.termsButton {
  color: var(--blue-secondary);
}

.termsButton:hover {
  opacity: 0.8;
}

.fab {
  position: fixed;
  top: 0;
  right: 0;
  margin: 16px;
  z-index: 50;
  font-size: 16px;
  background-color: var(--bluegray) !important;
  color: var(--white-or-black);
  text-transform: capitalize !important;
}

.passwordInstructions {
  padding: 8px;
  background-color: var(--white-or-black);
  border: 2px solid var(--faded);
  border-radius: 8px;
  margin: 8px 0;
  font-family: var(--mui-font);
}

.passwordInstructions ul li {
  font-size: 14px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  white-space: nowrap;
}

.passwordInstructions ul li svg {
  margin-right: 4px;
  width: 20px !important;
  height: 20px !important;
}

.passwordList {
  margin: 0;
  padding-left: 0px;
  list-style-type: none;
}

.notChecked {
  color: var(--gray);
  margin-right: 12px;
}

.checked {
  color: var(--green);
  margin-right: 12px;
}

.gettingStarted {
  font-family: var(--main-font);
  margin: 2px 0 8px;
  font-size: 16px;
}
